html, body, #root {
    height: 100%;
    margin:0;
    padding:0;
    font-family: sans-serif;
    box-sizing: border-box;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }
  