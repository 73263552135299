.rating-options {
    display:flex;
    gap:10px;
    margin-top:10px;
    flex-wrap:wrap;
  }
  
  .rating-btn {
    padding:5px 10px;
    border:none;
    border-radius:4px;
    background:#eee;
    cursor:pointer;
  }
  
  .rating-btn.selected {
    background:#007BFF;
    color:#fff;
  }
  