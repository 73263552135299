.place-card {
    position:relative;
    background:#fff;
    border-radius:8px;
    box-shadow:0 1px 4px rgba(0,0,0,0.2);
    margin-bottom:10px;
    cursor:pointer;
    transition:background-color 0.3s;
    padding:10px;
    padding-right:80px;
  }
  
  .place-card.selected {
    background:#f0f8ff;
  }
  
  .place-card-inner {
    display:flex;
    align-items:flex-start;
    gap:10px;
  }
  
  .place-image-container {
    flex:0 0 100px;
  }
  
  .place-image {
    width:100px;
    height:100px;
    object-fit:cover;
    border-radius:4px;
  }
  
  .no-image {
    width:100px;
    height:100px;
    background:#ccc;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#666;
    font-size:12px;
    text-align:center;
    border-radius:4px;
  }
  
  .place-details-container {
    flex:1;
    display:flex;
    flex-direction:column;
    position:relative;
  }
  
  .place-details-header {
    display:flex;
    align-items:center;
    justify-content:flex-start;
    gap:5px;
    position:relative;
  }
  
  .place-name-wrapper {
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    gap:5px;
  }
  
  .place-name {
    margin:0;
    font-size:16px;
    line-height:1.2;
  }
  
  .web-icon {
    cursor:pointer;
    font-size:16px;
    display:inline-flex;
    align-items:center;
  }
  
  .place-rating,
  .visited-rating {
    position:absolute;
    top:10px;
    right:10px;
    font-size:14px;
    font-weight:bold;
    white-space:nowrap;
  }
  
  .place-rating {
    color:#444;
  }
  
  .visited-rating {
    color:green;
  }
  
  .place-remarks {
    margin-top:10px;
    font-size:14px;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  
  .edit-icon-container, .delete-icon-container, .visited-icon-container {
    position:absolute;
    bottom:10px;
    cursor:pointer;
    font-size:18px;
    transition:transform 0.2s;
    color:#800000 !important; 
  }
  
  .visited-icon-container {
    right:80px;
  }
  
  .visited-icon-container:hover {
    transform:scale(1.1);
  }
  
  .edit-icon-container {
    right:40px;
  }
  
  .delete-icon-container {
    right:10px;
  }
  
  .edit-icon-container:hover, .delete-icon-container:hover {
    transform:scale(1.1);
  }
  
  .notes-icon {
    cursor:pointer;
    font-size:16px;
    color:#800000;
    position:relative;
  }
  
  .notes-popup {
    position:absolute;
    bottom:100%;
    left:50%;
    transform:translateX(-50%);
    background:#fff;
    color:#000;
    padding:10px;
    border-radius:4px;
    box-shadow:0 1px 4px rgba(0,0,0,0.2);
    width:200px;
    z-index:99999;
    font-size:14px;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  
  .notes-popup p {
    margin:0;
  }
  