.app-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.map-container-fullscreen {
  width: 100%;
  height: 100%;
  position: relative;
}

.list-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
}

.add-place-fab {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  font-size: 14px;
  z-index: 50;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .app-container.mobile {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative; /* Ensure absolutely positioned elements sit within this container */
  }

  .add-place-fab {
    display: none;
  }

  .app-container.mobile .add-place-fab.mobile {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: #007BFF;
    color: #fff;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .mobile-list-fullscreen {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    padding-bottom: 60px;
  }

  .mobile-bottom-toggle {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #ccc;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
    border: 1px solid #999;
    z-index: 1000;
  }

  .mobile-bottom-toggle .toggle-btn {
    background: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    color: #000;
  }

  .toggle-btn.active {
    background: #fff;
    font-weight: bold;
  }

  .mobile-back-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    z-index: 1001;
  }

  /* NEW/UPDATED STYLES FOR FOCUSED VIEW */
  /* Make the map truly fullscreen behind the card */
  .map-container-fullscreen {
    position: absolute; /* override the default relative for mobile */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* behind the card */
  }

  /* Position the card at the bottom center on mobile focused view */
  .focused-place-card {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    /*background: #fff;*/
    padding: 10px;
    /*box-shadow: 0 2px 6px rgba(0,0,0,0.2);*/
    border-radius: 8px;
    z-index: 1002; /* on top of the map */
  }
}
