.places-list-wrapper {
    padding:10px 0;
  }
  
  .section-header {
    padding:5px 10px;
    margin-bottom:5px;
    display:flex;
    align-items:center;
    gap:5px;
    cursor:pointer;
    font-size:14px;
    font-weight:bold;
    color:#555;
    border-radius:4px;
  }
  
  .places-list {
    margin-bottom:0;
  }
  
  .places-list p {
    margin:10px;
    font-size:14px;
    color:#666;
  }
  