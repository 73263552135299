.modal-overlay {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    display:flex;
    justify-content:center;
    align-items:center;
    padding:20px;
    z-index:999;
    overflow:auto;
    box-sizing:border-box;
  }
  
  .modal-content {
    background:#fff;
    padding:20px;
    border-radius:8px;
    max-height:90%;
    overflow:auto;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    box-sizing:border-box;
    width:90%;
    max-width:600px;
  }
  
  .modal-content h2 {
    margin-top:0;
    text-align:center;
    width:100%;
    font-weight:normal;
  }
  
  .modal-search-container {
    width:100%;
    display:flex;
    flex-direction:column;
    position:relative; /* To position predictions-list correctly */
  }
  
  .autocomplete-input {
    width:100%;
    padding:8px;
    margin-bottom:10px;
    box-sizing:border-box;
    font-size:14px;
  }
  
  .predictions-list {
    border:1px solid #ccc;
    background:#fff;
    max-height:300px; /* Keep enough height for scrolling suggestions */
    overflow-y:auto;
    margin:0;
    padding:0;
    list-style:none;
    width:100%;
    box-sizing:border-box;
    position:absolute; /* Absolutely positioned below the input */
    top:40px; /* Adjusted to ensure it appears just below the input */
    left:0;
    z-index:1010; /* Ensure it's above other elements */
    border-radius:4px;
    box-shadow:0 2px 8px rgba(0,0,0,0.15);
  }
  
  .predictions-list li {
    padding:8px;
    cursor:pointer;
    border-bottom:1px solid #eee;
    font-size:14px;
  }
  
  .predictions-list li:hover {
    background:#f0f0f0;
  }
  
  .place-details {
    margin-top:20px;
    width:100%;
  }
  
  .place-details p,
  .place-details label,
  .place-details textarea {
    font-size:14px;
  }
  
  .place-details textarea {
    width:100%;
    padding:8px;
    margin-top:5px;
    box-sizing:border-box;
    font-family:inherit;
    resize:vertical;
  }
  
  .modal-buttons {
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
    width:100%;
  }
  
  .modal-buttons button {
    padding:8px 12px;
    margin-left:10px;
    border:none;
    border-radius:4px;
    cursor:pointer;
    font-size:14px;
  }
  
  .modal-buttons button:nth-child(1) {
    background:#ccc;
  }
  
  .modal-buttons button:nth-child(2) {
    background:#007BFF;
    color:#fff;
  }
  
  .visited-modal-content {
    width:90%;
    max-width:400px;
  }
  
  .visited-modal-rating {
    width:90%;
    margin-top:10px;
  }
  
  .visited-modal-notes {
    width:90%;
    margin-top:20px;
  }
  
  .rating-options {
    display:flex;
    gap:10px;
    margin-top:10px;
    flex-wrap:wrap;
  }
  
  .rating-btn {
    padding:5px 10px;
    border:none;
    border-radius:4px;
    background:#eee;
    cursor:pointer;
  }
  
  .rating-btn.selected {
    background:#007BFF;
    color:#fff;
  }
  
  .category-options {
    margin-top:10px;
  }
  
  .category-buttons {
    display:flex;
    gap:10px;
    margin-top:5px;
  }
  
  .category-btn.selected {
    background:#ccc !important;
  }
  
  /* Adjustments for predictions-list on mobile */
  @media (max-width:768px) {
    .modal-content {
      width:90%;
      max-width:400px;
      padding:10px;
    }
  
    .predictions-list {
      max-height:200px; /* Reduced max-height for better fit on mobile */
      top:38px; /* Adjust positioning for mobile */
    }
  }
  