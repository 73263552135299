.visited-modal-content {
    width:90%;
    max-width:400px;
  }
  
  .visited-modal-rating {
    margin-top:10px;
  }
  
  .visited-modal-notes {
    margin-top:20px;
    width:90%;
  }
  
  .modal-overlay {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    display:flex;
    justify-content:center;
    align-items:center;
    padding:20px;
    z-index:999;
    overflow:auto;
    box-sizing:border-box;
  }
  
  .modal-content {
    background:#fff;
    padding:20px;
    border-radius:8px;
    max-height:90%;
    overflow:auto;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    box-sizing:border-box;
    width:90%;
    max-width:600px;
  }
  
  .modal-buttons {
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
    width:100%;
  }
  
  .modal-buttons button {
    padding:8px 12px;
    margin-left:10px;
    border:none;
    border-radius:4px;
    cursor:pointer;
    font-size:14px;
  }
  
  .modal-buttons button:nth-child(1) {
    background:#ccc;
  }
  
  .modal-buttons button:nth-child(2) {
    background:#007BFF;
    color:#fff;
  }
  